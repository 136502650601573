<template>
<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        
        <a-form layout='horizontal' 
            :form="form" 
            style="padding:0 24px" 
            :label-col="{span:5}" 
            :wrapper-col="{span:12}"
            @submit="handleSubmit"
            >
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <h5>{{$t('product.addurl.description')}}</h5>
            </a-form-item>

            <a-form-item label="Url">
                <a-input
                    v-decorator="['url', {rules:[{required:true}]}]"
                >
                </a-input>
            </a-form-item> 
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit" icon="check" v-if="!validating">{{ $t('product.addurl.validate')}}</a-button>
                <a-button danger html-type="submit" icon="loading" disabled="" v-if="validating">{{ $t('product.addurl.validating')}}</a-button>
                <a-space></a-space>
                <a-button type="secondary" icon="close" style="margin-left:10px" @click="goback">Cancel</a-button>
            </a-form-item>
        </a-form>
        <a-row style="color:white;padding:0 24px">
            <a-col class="ant-col-12 ant-col-offset-5">
                {{ vstatus }} {{ vdata }}
            </a-col>
        </a-row>
        <a-row style="color:white;padding:0 24px">
            <a-col class="ant-col-12 ant-col-offset-5">
                <a-button type="primary" html-type="button" icon="save" v-if="stopcheck && vstatus>0" @click="save">Save</a-button>
                <a-button type="primary" html-type="button" icon="save" v-if="stopcheck && vstatus<0" @click="save">Save anyway</a-button>
            </a-col>
        </a-row>
    </a-card>    
</template>
<script>
import {addurl, getprice, getvalidationid, getvalidationstatus} from '@/api/product';
import notification from 'ant-design-vue/es/notification'


export default({
    data(){
        return {
            form:this.$form.createForm(this,{name: 'producturl'}),
            vstatus:"",
            testid: "",
            vdata: "",
            stopcheck: false,
            currenturl: '',
            validating: false
        }
    },
    props:{
        productid:String
    },
    methods:{
        goback(){
            this.$router.go(-1);
        },
        save(){
            console.log(this.currenturl + '_' + this.productid);
            var values = {
                url: this.currenturl,
                id: this.productid
            };
            addurl(values).then(data=>{
                if (data == null){
                    notification.success({message:`Url has been added successfully!`});
                    this.$router.go(-1);
                }
                else{
                    notification.error({message:`URL limit reached for your current service package. Upgrade your service package if needed.`});
                }

            });
        },
        startcheck(){
            if (!this.stopcheck)
            {
                var that = this;
                getvalidationstatus({testid:this.testid}).then(
                    result=>{
                        console.log(result);
                        that.vstatus = result.data.vstatus;
                        that.vdata = result.data.vdata;
                        if ( (result.data.vstatus>-1) && (result.data.vstatus<3)) {
                            setTimeout(() => {
                                that.startcheck();
                            }, 3000);
                        } else {
                            that.stopcheck = true;
                            that.validating= false;
                        }
                    }
                );
            }
        },
        handleSubmit(e){
            this.stopcheck=false;
            this.validating = true;
            this.vstatus = '';
            this.vdata = '';
            e.preventDefault();
            this.form.validateFields((err,values)=>{
                if (!err){
                    console.log(values);
                    this.currenturl = values.url;
                    getvalidationid(values).then(
                        result=> {
                            console.log(result);
                            this.testid= result.testid;
                            this.startcheck();
                        }
                    );   
                }
            });
        }
    }
})
</script>
